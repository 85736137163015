<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Building Form
    </template>
    <template slot="body">
      <FormText
        v-model="code"
        label="Building Code"
        @keydownEnter="saveBuilding"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveBuilding"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    code: null,
  }),
  mounted() {
    if (this.item) {
      this.code = this.item.code;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveBuilding() {
      if (this.item) {
        this.request(`buildings/${this.item.building_id}`, 'put', { code: this.code }, () => {
          this.fireSuccess('Building updated successfuly');
          this.$emit('onSaved');
          this.close();
        });
      } else {
        this.request('buildings', 'post', { code: this.code }, () => {
          this.fireSuccess('Building created successfuly');
          this.$emit('onSaved');
          this.close();
        });
      }
    },
  },
};
</script>
<style></style>
