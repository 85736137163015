<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <md-button
          class="md-just-icon md-round md-primary pull-right"
          @click="newBuilding"
        >
          <md-icon>add</md-icon>
          <md-tooltip>New Building</md-tooltip>
        </md-button>
        <vue-table
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getData"
          @onEdit="onEdit"
          @onRemove="onRemove"
        />
      </md-card-content>
    </md-card>
    <BuildingsModal
      v-if="showModal"
      :item="selectedBuilding"
      @close="close"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import BuildingsModal from './BuildingsModal.vue';

export default {
  components: { VueTable, BuildingsModal },
  data: () => ({
    showModal: false,
    loading: false,
    selectedBuilding: null,
    vTable: {
      headers: [
        {
          title: 'code',
          mask: 'Building Code',
          sortable: true,
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-info',
          tooltip: 'Edit Building',
          callback: 'onEdit',
          icon: 'edit',
        },
        {
          buttonClass: 'md-danger',
          tooltip: 'Remove Building',
          callback: 'onRemove',
          icon: 'close',
        },
      ],
    },
  }),
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.loading = true;
      this.request('buildings', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    close() {
      this.showModal = false;
      this.selectedBuilding = null;
    },
    newBuilding() {
      this.showModal = true;
      this.selectedBuilding = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedBuilding = item;
    },
    onRemove(item) {
      this.request(`buildings/${item.building_id}`, 'delete', {}, () => {
        this.fireSuccess('Building deleted successfuly');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style>

</style>
